
export default {
  data: () => {
    return {
      postType: null
    };
  },
  async fetch() {
    this.postType = "page";
  }
};
